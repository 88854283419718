import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppUpdateService } from '../app/services/appUpdate/app-update.service';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  loginStatus = localStorage.getItem('loginStatus');
  appVersionNumber:string;
  constructor(
    private router: Router,
    private platform: Platform,
    private appUpdateService: AppUpdateService,
    private appVersion: AppVersion,

    ) {
      this.initializeApp();
    }

    async initializeApp() {
      this.checkForUpdate();
    }
    
  ngOnInit() {
    console.log("loginstatus",this.loginStatus);
   
    if (this.loginStatus == '0' || this.loginStatus == null) {
      this.router.navigate(['/login']);
      console.log("not login");

    } else {
      this.router.navigate(['/tabs/dashboard']);
    }
  }

  async checkForUpdate() {
    const shouldUpdate = await this.appUpdateService.checkForUpdate();
  }

  
}
