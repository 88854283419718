// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  amplify: {
  
    Auth: {
      identityPoolId: 'us-east-1:90b496d3-80a6-4fa7-91c2-65cd4a97a6b8',
      region: 'us-east-1',
    },
    geo: {
      AmazonLocationService: {
        maps: {
          items: {
            AmazonMap: {
              style: 'Default style',
            }
          },
          default: 'AmazonMap',
        },
        region: 'us-east-1',
      },
    },
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
