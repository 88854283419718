import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { config } from 'src/environments/config';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestService {
  httpOptions: any = {
    headers:{
      'Content-Type': 'application/json',
      // Authorization : 'AB123'

      // 'username':'',
      // 'hub_store':''
    }

  }
  constructor(
    private httpClient: HttpClient,
    
  ) { }

  handleError(error: HttpErrorResponse) {
    console.log('handle',error);

    let errorObj = {
      status:false,
      msg: ''
    };
    if(error.error instanceof ErrorEvent) {
      console.log('client');

      errorObj.msg = error.error.message; //Client Side Errors
    } else {
      console.log('server');

      errorObj.msg = 'Internal Server Error';
    }

    return throwError(errorObj)
  }

  public request(endpoint: string , payload: Object = null , method:string = 'POST'){


    this.httpOptions['body'] = payload;

    return this.httpClient.request(method,config.API_HOST + '/v2/' + endpoint,this.httpOptions).pipe(catchError(this.handleError.bind(this)));

  }
}
