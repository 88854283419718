import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MapComponent } from '../app/components/map-view/map-view.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'map',
    component: MapComponent,
  },
  {
    path: 'swap',
    loadChildren: () => import('./pages/swap/swap.module').then( m => m.SwapPageModule)
  },
  {
    path: 'register-driver',
    loadChildren: () => import('./pages/register-driver/register-driver.module').then( m => m.RegisterDriverPageModule)
  },
  {
    path: 'recharge-wallet',
    loadChildren: () => import('./pages/recharge-wallet/recharge-wallet.module').then( m => m.RechargeWalletPageModule)
  },
  {
    path: 'transactions',
    loadChildren: () => import('./pages/transactions/transactions.module').then( m => m.TransactionsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'station-detail',
    loadChildren: () => import('./pages/station-detail/station-detail.module').then( m => m.StationDetailPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'smart-swap',
    loadChildren: () => import('./pages/smart-swap/smart-swap.module').then( m => m.SmartSwapPageModule)
  },
  {
    path: 'wallet',
    loadChildren: () => import('./pages/wallet/wallet.module').then( m => m.WalletPageModule)
  },
  {
    path: 'bluetooth',
    loadChildren: () => import('./pages/bluetooth/bluetooth.module').then( m => m.BluetoothPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
