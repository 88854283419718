import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { DatepickerModule } from 'ng2-datepicker';
import { BarcodeScanner,BarcodeScannerOptions } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment'; 
import { ModalExampleComponent } from './pages/tab2/modal-example.component';
import { AppVersion } from '@ionic-native/app-version/ngx'; // Import the AppVersion service
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { BluetoothLE } from '@ionic-native/bluetooth-le/ngx';

import {FormsModule} from '@angular/forms';
@NgModule({
  declarations: [AppComponent,ModalExampleComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,HttpClientModule,DatepickerModule,FormsModule, ServiceWorkerModule.register('ngsw-worker.js', {
  enabled: environment.production,
  // Register the ServiceWorker as soon as the application is stable
  // or after 30 seconds (whichever comes first).
  registrationStrategy: 'registerWhenStable:30000'
})],
  providers: [
    Geolocation,
    NativeGeocoder,
    BarcodeScanner,
    FirebaseX,
    AppVersion,
    InAppBrowser,
    BluetoothLE,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
  bootstrap: [AppComponent],
})
export class AppModule {}
