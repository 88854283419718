import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { HttpRequestService } from '../http-request/http-request.service';
import { config } from 'src/environments/config';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { AlertController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {
  private serverAppVersion: string;
          appVersionNumber: string;

  constructor(
    private platform: Platform,
    private appVersion: AppVersion,
    private httpService: HttpRequestService,
    private alertController: AlertController, private iab: InAppBrowser
  ) {}

  async checkForUpdate(): Promise<boolean> {
    await this.platform.ready();
    const appVersion = await this.appVersion.getVersionNumber();
    this.appVersionNumber = appVersion;
    console.log("appVersionNumber",this.appVersionNumber);
    const updateUrl = `global/app-version`;

    const response = await this.httpService.request(updateUrl).toPromise();
    this.serverAppVersion = response['version'];

    let shouldUpdate = await  this.compareVersions(this.serverAppVersion, appVersion);
    if(shouldUpdate) {
        const alert = await this.alertController.create({
            header: 'Update Available',
            message: 'A new version of the app is available. Please update to the latest version to continue using the app.',
            backdropDismiss: false,
            buttons: [
              {
                text: 'Update',
                handler: () => {
                  const storeUrl = 'https://play.google.com/store/apps/details?id=com.app.ispartner';
                  this.iab.create(storeUrl, '_system');
                }
              }
            ]
          });
  
          await alert.present();
          return true;
    } else {
        return false;
    }
  }

  private compareVersions(serverVersion: string, currentVersion: string): boolean {
    const serverNums = serverVersion.split('.');
    const currentNums = currentVersion.split('.');

    for (let i = 0; i < serverNums.length; i++) {
      if (parseInt(serverNums[i]) > parseInt(currentNums[i])) {
        return true;
      } else if (parseInt(serverNums[i]) < parseInt(currentNums[i])) {
        return false;
      }
    }
    return false;
  }
}
