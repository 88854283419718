export const apiURL: any = {
    login: 'auth/login',
    send_otp:'auth/send-otp',
    drivers: "drivers",
    rechargeWallet:"recharge-wallet",
    stnDetail:"station-detail",
    smartStation:"station-detail/smart-station",
    stationDoor:"station-comm/door",
    stationUpdate:"station-detail/smart-station/update-slot",
    notifications:"notifications",
    
    //DASHBOARD
    dashboard: {
        dash: 'dashboard',
        transactions:'dashboard/transactions',
        inputBatScan:'swap/input-bat',
        outputBatScan:'swap/output-bat',
        costBreakdown:'swap/costing-info',
        fcmUpdate:'fcm-token', 
        walletSummary:'wallet/summary',
    },
    profile: {
        summary: 'profile/summary'
    },
    smartSwap: "smart-swap",
    swap: {
        new: 'swap/new-swap'
    },
    smartSwapsSteps: {
        network:"smart-swap/network",
        openSlot:"smart-swap/open-slot",
        newBatt: "smart-swap/issue-new",  
        newSwap:"smart-swap/new-swap"
    },
    states: 'mobile/get-cities',
    cities: 'mobile/get-cities',
    reports: 'reports',
    transactions:'transactions',
    payout:'payout',
    wallet:{
        recharge: 'wallet/recharge',
        dash:'wallet/dashboard',
        create:'wallet/create-order',
        verify:'wallet/verify-payment'
    },
    devices:{
        getAll: 'devices/get-all'
    },
    editSwap: {
        edit:"swap/edit",
    },
    commonFunc: {
        imageUpload:"global/image-upload"
    },
    register: {
        registerDriver:'drivers/register',
        updateDriver:'drivers/register/update-driver'
    }

}

