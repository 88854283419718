import { Component } from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: 'app-map',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss'],
})
export class MapComponent {
  constructor() {}

  ionViewDidEnter() {
    const map = L.map('mapid').setView([51.505, -0.09], 13);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
    }).addTo(map);

    // Add markers for each device
    const devices = [
      { name: 'Device 1', lat: 51.508, lng: -0.11 },
      { name: 'Device 2', lat: 51.503, lng: -0.09 },
      { name: 'Device 3', lat: 51.51, lng: -0.12 },
    ];

    devices.forEach(function (device) {
      const marker = L.marker([device.lat, device.lng]).addTo(map);
      marker.bindPopup(device.name);
    });
  }
}
