import { Component,Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { apiURL } from '../../models/api';
import { HttpRequestService } from '../../services/http-request/http-request.service'
 
@Component({
  selector: 'app-modal-example',
  templateUrl: 'modal-example.component.html',
})
export class ModalExampleComponent {
  name: string;
  showLoader: boolean = false;
  userObject = JSON.parse(localStorage.getItem('userObject'));
  dataObj:any = {
    operator_id:this.userObject.id
  }
  @Input("type") type;
  constructor(private modalCtrl: ModalController,private httpService: HttpRequestService,) {}

  ngOnInit() {
    console.log(this.type);
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalCtrl.dismiss(this.name, 'confirm');
  }

  payout() {
    
    this.showLoader = true;

    console.log("this.dataObj",this.dataObj);
    
    this.httpService.request(apiURL['payout'],this.dataObj).subscribe(
      (response : any )=> {
        this.showLoader = false;
        if(response.status == 'success') {
          
        }
      },
      (err :any) => {
        this.showLoader = false;
        alert("Please check all fields")
      }
    )
  }
}